import React from "react"
import { graphql } from "gatsby"
import Layout from "../component/Layout"
// import "../styles/pages/blogmaint.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../component/SEO"

export const query = graphql`
  query($slug: String!) {
    contentfulDorlandsHolidaysBlog(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do YYYY")
      body {
        json
      }
    }
  }
`
// BLOG PAGE
const Blog = props => {
  // const Blog = () => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img className="blog-image" alt={alt} src={url} />
      },
    },
  }
  return (
    <Layout>
      {/* <h1 className="maint">
        Sorry the blog is undergoing some much needed maintenance
      </h1> */}
      <SEO title={props.data.contentfulDorlandsHolidaysBlog.title} />
      <div className="blog-container">
        <h1>{props.data.contentfulDorlandsHolidaysBlog.title}</h1>
        <p>{props.data.contentfulDorlandsHolidaysBlog.publishedDate}</p>
        <div className="blog-content">
          {documentToReactComponents(
            props.data.contentfulDorlandsHolidaysBlog.body.json,
            options
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Blog
